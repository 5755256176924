@keyframes scaling {
  to {
    transform: scale(1.05);
  }
}

.video-container {
  width: 100%;
  height: 90vh;
  background-color: var(--bg-color);
  position: relative;
}

.video-container__background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.video-container__text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  z-index: 1;
}

.video-container__button {
  margin: 30px 0 0;
  animation: scaling ease 1s infinite alternate;
  align-self: flex-start;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--bg-color);
  border: 1px solid white;
  box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, .3);
  color: var(--text-color);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--font-size-medium);
  line-height: 1.21;
  cursor: pointer;
}

@media screen and (min-width: 568px ){
  .video-container {
    width: 60%;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, .3);
  }
}

@media screen and (min-width: 768px ){
  .video-container {
    width: 60%;
  }
}

@media screen and (min-width: 984px ){
  .video-container {
    width: 40%;
  }
}

.card__info {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  color: var(--text-color);
  background-color: var(--bg-color);
  border: 1px solid var(--main-color);
  border-radius: 10px;
  box-sizing: border-box;
}

.card__main-price {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  color: var(--text-color);
  background-color: var(--bg-color);
  border: 1px solid var(--main-color);
  border-radius: 10px;
  box-sizing: border-box;
}

.card__new-price {
  position: absolute;
  top: 50px;
  left: 10px;
  padding: 5px;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  color: var(--text-color-dark);
  background-color: black;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  box-sizing: border-box;
}

.card__description {
  position: absolute;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--font-family);
}

.card__description::after {
  border-radius: 10px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(29, 17, 4, 0.7);
  filter: blur(30px);
}

.card__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: 400;
  font-size: var(--font-size-medium);
  line-height: 1.21;
  letter-spacing: 1px;
  filter: none;
  z-index: 1;
}

.card__text {
  margin: 0;
  color: #d2d2d2;
  font-weight: 200;
  font-size: var(--font-size-small);
  line-height: 1.4;
  filter: none;
  z-index: 1;
}

.card__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1.4;
}

.card__button {
  padding: 0 5px 0 0;
  align-self: flex-start;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid white;
  color: var(--text-color);
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: var(--font-family);
  font-weight: 200;
  font-size: var(--font-size-small);
  line-height: 1.21;
  cursor: pointer;
  transition: transform .6s ease-in-out;
  z-index: 1;
}

.card__button:hover {
  transform: scale(1.1);
}

.card__button-image {
  padding: 5px;
  width: 16px;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Light.woff2') format('woff2'),
         url('SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Regular.woff2') format('woff2'),
       url('SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Bold.woff2') format('woff2'),
         url('SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}



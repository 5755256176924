.header {
  width: 90%;
  padding-top: 20px;
  position: absolute;
  display: flex;
  left: 5%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.header__logo {
  width: 68px;
  height: 68px;
  object-fit: contain;
}

.header__menu-button {
  position: fixed;
  right: 5%;
  width: 40px;
  height: 40px;
  border: 2px solid var(--bg-color);
  border-radius: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--bg-color);
  background-image: url('../../images/buttons/menu.svg');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: .7s ease;
}

.header__menu-button:hover {
  opacity: .6;
}

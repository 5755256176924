@keyframes gif__anim-scroll-hand {
  0% {
    transform: translateX(80px) scale(1);
    opacity: 0;
  }
  10% {
    transform: translateX(80px) scale(1);
    opacity: 1
  }
  20%, 60% {
    transform: translateX(175px) scale(.6);
    opacity: 1;
  }
  80% {
    transform: translateX(5px) scale(.6);
    opacity: 1;
  }
  to {
    transform: translateX(5px) scale(.6);
    opacity: 0;
  }
}

  @keyframes gif__anim-scroll-card {
  0%, 60% {
    transform: translateX(0)
  }
  80%, to {
    transform: translateX(-240px)
  }
}

@keyframes rotation {
  100%{
    transform: rotate(360deg);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.section-title-container {
  margin-left: 5%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
}

.section-title {
  margin: 0 0 0 5%;
  color: var(--main-color);
  text-transform: uppercase;
  font-family: var(--font-family);
  font-weight : 800;
  font-size: var(--font-size-large);
  line-height: 1.21;
}

.section-title_light {
  color: var(--bg-color);
}

.section-title_pos_lead {
  margin: 0;
}

.section-subtitle {
  margin: 0;
  text-align: right;
  color: var(--main-color);
  font-family: var(--font-family);
  font-weight: 200;
  font-size: var(--font-size-medium);
  line-height: 1.21;
}

.section-decor_light {
  background-color: var(--bg-color-light);
}

.rotate {
  animation: rotation 3s infinite forwards linear;
}

.gif {
  margin-right: 5%;
  align-self: flex-end;
  width: 42px;
  height: 42px;
}

.gif__hand {
  animation: gif__anim-scroll-hand 2s infinite
}

.gif__card {
  animation: gif__anim-scroll-card 2s infinite
}

.decor {
  width: 100%;
  background-color: var(--bg-color-light);
}

.decor_dark {
  background-color: var(--bg-color);
}

.decor__item {
  width: 100%;
  height: 30px;
  background-color: var(--bg-color);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
}

.decor__item_light {
  background-color: var(--bg-color-light);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}


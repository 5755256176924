
.App {
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color);
  min-height: 100vh;
  min-width: 320px;
  max-width: 1440px;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

.contacts {
  padding: 10px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--bg-color);
  overflow: hidden;
}

.contacts__container {
  width: 100%;
  padding: 30px 5% 0;
  box-sizing: border-box;
  border-top: 1px solid var(--bg-color-light);
  display: grid;
  grid-template-columns: 140px 1fr;
  align-items: center;
}

.contacts__social-icons {
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
}

.contacts__social-icon {
  width: 40px;
  height: 40px;
  animation: scale 3s infinite linear alternate;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: .7s ease;
}

.contacts__social-icon_whatsApp {
  background-image: url('../../images/socialIcons/whatsappIcon.svg');
}

.contacts__social-icon_inst {
  background-image: url('../../../src/images/socialIcons/instIcon.svg');
}

.contacts__social-icon_telegram {
  background-image: url('../../../src/images/socialIcons/telegramIcon.svg');
}

.contacts__social-icon:hover {
  opacity: .7;
}

.contacts__image {
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: 568px ){
  .contacts__social-icons {
    flex-direction: row;
    gap: 100px;
  }

  .contacts__container{
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .contacts__image {
    width: 200px;
  }
}

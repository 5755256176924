.burger {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, .75);
  font-family: var(--font-family);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: .6s ease-in;
}

.burger_opened {
  opacity: 1;
  visibility: visible;
}

.burger__container {
  padding: 8% 5%;
  box-sizing: border-box;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: var(--bg-color);
  border-left: 1px solid var(--main-color);
}

.burger__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger__close-button {
  align-self: flex-end;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-image: url(../../images/buttons/closeButton-light.svg);
  background-repeat: no-repeat;
  cursor: pointer;
}

.burger__close-button:hover {
  opacity: .6;
}

.burger__menu {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.burger__links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px
}

.burger__link {
  color: var(--text-color);
  font-size: var(--font-size-medium);
  font-weight: 400;
  line-height: 1.21;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: .7s ease;
}

.burger__link:hover {
  opacity: .6;
}

.burger__caption {
  max-width: 175px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.burger__text {
  font-weight: 200;
  color: var(--text-color);
}

.burger__text:nth-of-type(3) {
  margin-top: 10px;
}

.burger__social-icons {
  display: flex;
  justify-content: space-between;
}

.burger__social-icon {
  width: 30px;
  height: 30px;
  animation: scale 3s infinite linear alternate;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: .7s ease;
}

.burger__social-icon_whatsApp {
  background-image: url('../../images/socialIcons/whatsappIcon.svg');
}

.burger__social-icon_inst {
  background-image: url('../../../src/images/socialIcons/instIcon.svg');
}

.burger__social-icon_telegram {
  background-image: url('../../../src/images/socialIcons/telegramIcon.svg');
}

.burger__social-icon:hover {
  opacity: .7;
}

.footer {
  width: 100%;
  padding-bottom: 20px;
  background-color: var(--bg-color-light);
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.footer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer__logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.footer__text-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer__text-list {
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer__text {
  margin: 0;
  background-color: var(--bg-color-light);
  color: var(--bg-color);
  font-size: var(--font-size-small);
  font-weight: 100;
  line-height: 1.21;
}

.footer__link {
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: underline;
  color: var(--bg-color);
  cursor: pointer;
  transition: .7s ease;
}

.footer__link:hover {
  opacity: .6;
}

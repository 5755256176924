@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #c2b3a6;
  --main-color: white;
  --bg-color-light: #F4F1EC;
  --bg-color: #c2b3a6;
  --text-color-dark: #77746F;
  --text-color: white;
  --font-family: 'SF Pro Display', 'Arial';
  --font-size-large: 36px;
  --font-size-medium: 18px;
  --font-size-small: 14px;
}

.questions-section {
  padding: 10px 0 0;
  width: 100%;
  background-color: var(--bg-color-light);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.questions {
  width: 100%;
  padding: 30px 0 0;
  margin: 0;
  border-top: 1px solid var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  list-style: none;
  background-color: var(--bg-color-light);
  font-family: var(--font-family);
}

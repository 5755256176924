@keyframes preloader-scale {
  from {
    opacity: 0;
    transform: scale(.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes preloader-rotate {
  to {
    transform: rotate(360deg);
  }
}

.preloader {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  position: fixed;
  z-index: 5;
  transition: 1s ease;
}

.preloader_open {
  opacity: 1;
  visibility: visible;
}

.preloader__logo {
  position: absolute;
  width: 100px;
  height: 100px;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  background-size: contain;
  background-image: url(../../images/logo.png);
  animation: preloader-scale 0.9s ease, preloader-rotate 3.1s ease-in-out .9s;
}

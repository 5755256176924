.portfolio {
  padding: 10px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--bg-color);
}

.portfolio__container {
  width: 100%;
  overflow-x: scroll;
}

.portfolio__cards {
  margin: 0;
  list-style: none;
  padding: 30px;
  border-top: 1px solid var(--main-color);
  width: min-content;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

.portfolio__image {
  width: 250px;
  height: 400px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, .3);
  object-fit: fill;
}
